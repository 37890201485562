import React from "react";
import img1 from "../../assets/images/ser1.png";
import img2 from "../../assets/images/ser2.png";
import img3 from "../../assets/images/ser3.png";
import img4 from "../../assets/images/ser4.png";
import { FaPhone } from "react-icons/fa6";
import oneway from "../../assets/images/oneway.jpeg"
import roundtrip from "../../assets/images/roundtrip.jpeg"
import airporttrip from "../../assets/images/airporttrip.png"
import Packages from "../../assets/images/package.png";

const OurServices = () => {
  return (
    <div>
      <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
          Our Service
        </h4>
        <p className='text-black text-center opacity-60 md:text-lg mt-5'>
          
          Book your ride today and enjoy a seamless travel experience with our reliable drop taxi service from Vellore to Chennai & Chennai to Vellore               </p>
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4  gap-x-3 sm:gap-y-2 md:gap-y-3  items-center px-4 py-5 bg-[#ffffff] justify-around rounded-md'>

            <div className='grid grid-cols-1 gap-x-3 sm:gap-y-2 md:gap-y-3  items-center px-4 py-5 bg-[#ffffff] justify-around rounded-md'>
              <div className="flex flex-col items-center gap-y-4">
                < div className="w-[100px] h-[100px]">
                   <img src ={oneway}/>
                   </div>
                <p className="font-bold text-3xl">One Way Trip</p>
                <p className="text-center">Convenient drop services from Vellore to Chennai.</p>
              </div>
            </div>
            <div className='grid grid-cols-1 gap-x-3 sm:gap-y-2 md:gap-y-3  items-center px-4 py-5 bg-[#ffffff] justify-around rounded-md'>
              <div className="flex flex-col items-center gap-y-3">
              <div className="w-[100px] h-[100px]">

                   <img src ={roundtrip}/></div>
                <p className="font-bold text-3xl">Round Trip</p>
                <p className="text-center">Option for round trips available upon request.</p>
              </div>
            </div>
            <div className='grid grid-cols-1  gap-x-3 sm:gap-y-2 md:gap-y-3  items-center px-4 py-5 bg-[#ffffff] justify-around rounded-md'>
              <div className="flex flex-col items-center gap-y-3">
              <div className="w-[100px] h-[100px]">

                   <img src ={airporttrip}/>
                   </div>
                <p className="font-bold text-3xl">Airport taxi</p>
                <p className="text-center">Reliable transportation to and from the airport.</p>
              </div>
            </div>
            <div className='grid grid-cols-1  gap-x-3 sm:gap-y-2 md:gap-y-3  items-center px-4 py-5 bg-[#ffffff] justify-around rounded-md'>
              <div className="flex flex-col items-center gap-y-3">
              <div className="w-[100px] h-[100px]">

                   <img src ={Packages} className="w-[100px] h-[100px]"/>
                   </div>
                <p className="font-bold text-2xl">Custom Packages</p>
                <p className="text-center">Reliable transportation to and from the airport.</p>
              </div>
            </div>

          </div>
        {/* <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Drop Taxi Services:</h3>
          <p>
            Whether you need a ride across town or to a neighboring city, our drop taxi services offer a convenient and cost-effective solution for your travel needs.

          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Airport Transfers:</h3>
          <p>
            Start or end your journey hassle-free with our reliable airport transfer services. We'll ensure you reach your destination on time, relaxed, and ready for your flight.

          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Outstation Trips:</h3>
          <p>
            Explore the beauty of Tamil Nadu and beyond with our customizable outstation trip packages. Sit back, relax, and let us take you on an unforgettable journey.
            Book Your Ride Today!
          </p>
        </div>
        <p className="mt-4">
          Experience the ultimate in convenience, reliability, and affordability with Vellore Dropme Taxi. Book your ride today and discover why we're the preferred choice for drop taxi services in Tamil Nadu. Get in touch with us now to plan your next journey!
        </p> */}
      </div>

      <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
          Point-to-Point Drop Taxi Services        </h4>
        <div className=" flex flex-col md:flex-row lg:flex-row justify-around mt-3">
          <div className="pt-4">
            <h2 className="text-2xl  md:text-3xl lg:text-3xl font-medium">Vellore to Chennai</h2>
            <div className="flex flex-col gap-y-[10px] pt-3 pl-3">

              <h3 className="font-semibold text-xl md:text-2xl lg:text-2xl">•  Direct Travel</h3>
              <p>
                Enjoy a seamless and direct ride from Vellore to Chennai.          </p>
            </div>
            <div className="flex flex-col gap-y-[10px] pt-4 mt-4 pl-3">
              <h3 className="font-semibold text-xl md:text-2xl lg:text-2xl">•  Fixed Rates</h3>
              <p>
                Transparent pricing with no hidden fees.          </p>
            </div>
            <div className="flex flex-col gap-y-[10px] pt-4 mt-4 pl-3">
              <h3 className="font-semibold text-xl md:text-2xl lg:text-2xl">•  Comfort & Safety</h3>
              <p>
                Clean, sanitized cars driven by professional drivers.
              </p>
            </div>
            <div className="flex flex-col gap-y-[10px] pt-4 mt-4 pl-3">
              <h3 className="font-semibold text-xl md:text-2xl lg:text-2xl">•  24/7 Availability</h3>
              <p>
                Book anytime for your convenience.          </p>
            </div>
          </div>

          <div className="pt-4">
            <h2 className="text-2xl  md:text-3xl lg:text-3xl font-medium font-medium">Chennai to Vellore</h2>
            <div className="flex flex-col gap-y-[10px] pt-3 pl-3">

              <h3 className="font-semibold text-xl md:text-2xl lg:text-2xl">•  Convenient Return Trips</h3>
              <p>
                Reliable drop service from Chennai to Vellore.          </p>
            </div>
            <div className="flex flex-col gap-y-[10px] pt-4 mt-4 pl-3">
              <h3 className="font-semibold text-xl md:text-2xl lg:text-2xl">•  Same High Standards</h3>
              <p>
                Consistent quality of service and vehicle cleanliness.          </p>
            </div>
            <div className="flex flex-col gap-y-[10px] pt-4 mt-4 pl-3">
              <h3 className="font-semibold text-xl md:text-2xl lg:text-2xl">•	Flexible Scheduling</h3>
              <p>
                Choose your preferred time and date for travel.          </p>
            </div>
            <div className="flex flex-col gap-y-[10px] pt-4 mt-4 pl-3">
              <h3 className="font-semibold text-xl md:text-2xl lg:text-2xl" >•  Easy Online Booking</h3>
              <p>Simple and quick booking process </p>
            </div>

          </div>
        </div>
        <div>    <p className='text-black text-center opacity-60 md:text-lg mt-5'>
          Travel comfortably and safely with our point-to-point drop taxi services between Vellore and Chennai. Book your ride now!
        </p>
          <div className=' mt-5 flex justify-center gap-x-3'>
            <div>
              <a href="#book_taxi_form" className='th-btn'>Book Now</a></div>
            <div className=" text-white font-bold text-lg">
              <a
                href="tel:+919597256055"
                className=" rounded-md th-btn-reverse hover:bg-black transition-all ease-linear px-2 py-2"
              >
                <span className=" flex gap-x-2 items-center justify-center">
                  <FaPhone size={17} /> +91 9597256055
                </span>
              </a>
            </div>

          </div>

        </div>

      </div>
      <div className="  px-0 sm:px-4 md:px-8 lg:px-16 bg-[#f5f5f5]  py-3 md:py-5 mt-4 md:mt-6 lg:mt-8">
        <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2  text-center">
          <div className=" flex flex-col gap-y-1">
            <img src={img1} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Easy Online Booking
            </p>
            <p>Pickup and Drop on time</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img2} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Professional Drivers
            </p>
            <p>Well Trained years of experience</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img3} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Variety of Prime Brands
            </p>
            <p>SWIFT - ETIOS - INNOVA</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img4} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Secure Online Payment
            </p>
            <p>24 /7 Customer Support</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
